import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { Toast } from "../Deshboard/Notification";
const BookList = ({ reloadBookList, setReloadBookList }) => {
  const [bookList, setBookList] = useState([]);

  useEffect(() => {
    axios
      .get("https://nodes.arahman-art.com/eBook/post/data/get")
      .then((res) => {
        setBookList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [reloadBookList]);

  const handelDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(
            `https://nodes.arahman-art.com/eBook/data/delete/${id}`,

            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((res) => {
            setReloadBookList(!reloadBookList);
            Toast.fire({
              icon: "success",
              title: "Delete book   successfully",
            });
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `<p  href="">Please try again!!!!</p>`,
            });
          });
      }
    });
  };

  return (
    <>
      <h3 className="text-center text-success"> Book List </h3>

      <Table striped bordered hover size="sm">
        <thead>
          <tr className="table-heading-data">
            <th>Serial</th>
            <th>Books Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody className="unused-images-body">
          {bookList.map((x, index) => {
            return (
              <tr key={x.id}>
                <td>{index + 1}</td>
                <td>{x.topic}</td>
                <td>
                  {" "}
                  <button
                    onClick={() => handelDelete(x.id)}
                    className="btn btn-danger"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

export default BookList;
