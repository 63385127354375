import axios from 'axios';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import FolderSetting from './FolderSetting';
import SeriseFolderCreate from './SeriseFolderCreate';
import UploadImages from "./UploadImages";

const Series = () => {

    const [reload,setReload]=useState(false)
    const [folder, setFolder] = useState([]);

    useEffect(() => {
        axios.get("https://nodes.arahman-art.com/series/getFolder").then((res) => {
          setFolder(res.data);
        });
      }, [reload]);
    
    
    

    return (
        <>

       <div className="col-md-4 offset-md-1">
                
                <h4 className="text-gray shadow p-3">Create Your Series Folder</h4>
              <SeriseFolderCreate setReload={setReload}/>


                 <FolderSetting folder={folder}    setReload={setReload}/>
            
                 
              
          
          </div>

          <div className="col-md-5   offset-md-1">
          <h4 className="text-gray shadow p-3">Upload images on your series folder</h4>
               <UploadImages folder={folder} />
          
           </div>
            
        </>
    );
};

export default Series;