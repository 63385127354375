import axios from "axios";
import { convertToRaw } from "draft-js";
import { convertFromRaw } from "draft-js";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Table } from "react-bootstrap";
import Swal from "sweetalert2";
import { Toast } from "../Deshboard/Notification";
import "./E_Book.css";

const UnUsedImage = () => {
  const [bookData, setBookData] = useState([]);
  const [storageImageData, setStorageImageData] = useState([]);

  const [reload, setReload] = useState(false);

  useEffect(() => {
    //   get all book data
    axios
      .get("https://nodes.arahman-art.com/eBook/post/data/get")
      .then((res) => {
        const newData = res.data.map((x) => {
          const contentState = convertFromRaw(JSON.parse(x?.description));
          const rowData = convertToRaw(contentState);
          return rowData?.entityMap[0]?.data?.src;
        });

        setBookData(newData);
      })
      .catch((err) => console.log(err));

    //    get all image url from book image storage
    axios
      .get("https://nodes.arahman-art.com/eBook/post/images/get", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => setStorageImageData(res.data))
      .catch((err) => console.log(err));
  }, [reload]);

  const unUsedImageUrl = storageImageData.filter(function (n) {
    return !this.has(n.imgUrl);
  }, new Set(bookData));

  const handelDelete = (data) => {
    const index = data.imgUrl.lastIndexOf("/") + 1;
    const imageName = data.imgUrl.substr(index);

    axios
      .delete(
        `https://nodes.arahman-art.com/eBook/image/unused/delete/${data.id}/${imageName}`,

        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setReload(!reload);
        Toast.fire({
          icon: "success",
          title: "Delete Image  successfully",
        });
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `<p  href="">Please try again!!!!</p>`,
        });
      });
  };

  return (

    <>

  <h3 className="text-center text-danger"> Unused Images </h3>
    <p className="text-center "> (Please delete this images )</p>

  <Table striped bordered hover size="sm">
    <thead>
      <tr className="table-heading-data">
        <th>Serial</th>
        <th>Images</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody className="unused-images-body">
      {unUsedImageUrl.map((x, index) => {
        return (
          <tr key={x.id}>
            <td>{index + 1}</td>
            <td>
              {" "}
              <img src={x.imgUrl} alt="" />{" "}
            </td>
            <td>
              {" "}
              <button
                onClick={() => handelDelete(x)}
                className="btn btn-danger"
              >
                Delete
              </button>
            </td>
          </tr>
        );
      })}
    </tbody>
  </Table>

    </>

  );
};

export default UnUsedImage;
