import axios from "axios";
import React from "react";
import { useState } from "react";
import { BsCloudUpload } from "react-icons/bs";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";

const SliderImages = ({setImgReload}) => {
  const [images, setImages] = useState([]);
  const [Select, setSelect] = useState(false);


              const history=useHistory()


  const hendelImageUploaded = (e) => {
    
    if (e.target.files) {
      setSelect(false);
      if (e.target.files[0].size > 1000000) {
        setImages([]);

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "File Size too large ",
          footer: "<p> Maximum File Size Allow 1MB</p>",
        });
      } else {
        setImages(e.target.files);
      }
    }
  };

  //   save slider images on database
  const submitImages = () => {
    if (images.length > 0) {
      let formData = new FormData();
      formData.append("image", images[0]);

      axios
        .post("https://nodes.arahman-art.com/slider/image/insert", formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          if (response.data) {
            setImgReload((imgReload) => !imgReload);
            setImages([]);
            history.push("/admin/SliderSetting")
            Swal.fire("Good job!", "Added Successfully", "success");
          }
        })
        .catch((err) => {
          setImages([]);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer:
              "<p> Please check your Image size and Image type and try again</p>",
          });
        });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "You Are not selected any images!",
      });
    }
  };

  return (
    <div className="card p-4">
      <form enctype="multipart/form-data">
        <div className="mb-3 weight-UPimg">
          <h6>Select your slider photo</h6>

          <input
            style={{ display: "none" }}
            onChange={hendelImageUploaded}
            name="image"
            id="file"
            type="file"
            accept="image/*"
            multiple={false}
          />

          <label htmlFor="file">
            <BsCloudUpload /> Upload Photo
          </label>
        </div>
        {Select && (
          <p className="text-danger"> Please Select minimum One image </p>
        )}
        <p style={{ color: "gray" }}>
          {images.length > 0 && images.length + "  " + "Image Selected "}
        </p>
        <button
          className="btn btn-outline-primary"
          type="button"
          onClick={submitImages}
        >
          Save
        </button>
      </form>
    </div>
  );
};

export default SliderImages;
