import React, { useContext } from "react";
import "./SocialLink.css";
import {
  IoLogoFacebook,
  IoLogoLinkedin,
  IoLogoInstagram,
} from "react-icons/io";
import { userContext } from "../../App";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const SocialLink = () => {
  const { personalInfo } = useContext(userContext);

  const socialLink = [
    {
      link: personalInfo?.facebook,
      icon: <IoLogoFacebook />,
      name:"Facebook"
    },
    {
      link: personalInfo?.linkedIn,
      icon: <IoLogoLinkedin />,
      name:"LinkendIn"
    },
    {
      link: personalInfo?.instagram,
      icon: <IoLogoInstagram />,
      name:"instagram"
    },
  ];
  return (
    <>
      <ul className="d-flex ms-1 about-social-icon">
        {socialLink.map((x, index) => (
          <li key={index}>
            <a target="_blank" rel="noreferrer" className="icon" href={x.link}>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>{x.name}</Tooltip>}
              >
                {x.icon}
              </OverlayTrigger>
            </a>
          </li>
        ))}
      </ul>
    </>
  );
};

export default SocialLink;
