import React from "react";
import { Table } from "react-bootstrap";
import { FiEdit } from "react-icons/fi";
import { MdDeleteForever } from "react-icons/md";
import Alert from "react-bootstrap/Alert";
import Swal from "sweetalert2";
import axios from "axios";
import { baseUrl } from "../../../App";
import { Toast } from "../Deshboard/Notification";

const DescriptionSetting = ({ TData, hendelView }) => {
  const handelEdit = async (data, titleID) => {
    const { value: formValues } = await Swal.fire({
      title: "Details",
      html:
        `<input value=${data.fromTo}  placeholder=" From to " id="swal-input1" class="swal2-input">` +
        `<textarea  value=${data.description}  placeholder="Description" id="swal-input2" class="swal2-input">`,
      focusConfirm: false,

      preConfirm: () => {
        return {
          fromTo: document.getElementById("swal-input1").value,
          description: document.getElementById("swal-input2").value,
        };
      },
    });

    if (formValues) {
      const AllData = {
        ...formValues,
        id: data.id,
      };

      axios
        .patch(`${baseUrl}/profile/details/update`, AllData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          Toast.fire({
            icon: "success",
            title: "Update successfully",
          });
          hendelView(titleID);
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `<p  href="">Please try again!!!!</p>`,
          });
        });
    }
  };

  const hendelDelete = (id, titleID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${baseUrl}/profile/details/delete/${id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((result) => {
            Swal.fire("Deleted!", " Details  has been deleted.", "success");
            hendelView(titleID);
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `<p  href="">Please try again!!!!</p>`,
            });
          });
      }
    });
  };
  if (TData.length === 0) {
    return (
      <Alert className="mt-4" variant="danger">
        No Data fount !!!
      </Alert>
    );
  } else {
    return (
      <div className="card p-4 shadow my-3">
        <h3 className="text-center text-success"> Description Setting </h3>

        <Table striped bordered hover size="sm">
          <thead>
            <tr className="table-heading-data">
              <td className="text-center">Serial</td>
              <td className="text-center">From - To</td>
              <td className="text-center">Description </td>
              <td className="text-center">Action </td>
            </tr>
          </thead>
          <tbody className="folder-list-body">
            {TData.map((x, index) => {
              return (
                <tr key={x.id}>
                  <td className="text-center">{index + 1}</td>
                  <td className="text-center">{x.fromTo}</td>

                  <td className="text-center description">{x.description}</td>

                  <td className="text-center">
                    <button
                      onClick={() => handelEdit(x, x.titleID)}
                      className="btn"
                    >
                      <FiEdit className="seriesFolderEditeIcon" />
                    </button>
                    <button
                      onClick={() => hendelDelete(x.id, x.titleID)}
                      className="btn text-danger"
                    >
                      <MdDeleteForever className="deleteIcon" />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }
};

export default DescriptionSetting;
