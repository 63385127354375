import React, { useContext, useEffect, useState } from "react";
import "./About.css";
import { baseUrl } from "../../App";
import { userContext } from "../../App";
import axios from "axios";
import Layout from "../Layout/Layout";
import { AiOutlineDownload } from "react-icons/ai";
import TableData from "./TableData";
import profileImage from "../../resource/img/profile.jpg";

const About = () => {
  const { personalInfo } = useContext(userContext);
  const [title, setTitle] = useState([]);
  const [description, setDiscription] = useState([]);
  const [aboutDetails, setAboutDetails] = useState([]);

  // get title
  useEffect(() => {
    axios
      .get(`${baseUrl}/profile/title/get`)
      .then((res) => {
        setTitle(res.data);
      })
      .catch((err) => {});
  }, []);

  // get discription
  useEffect(() => {
    axios
      .get(`${baseUrl}/profile/description/get`)
      .then((res) => {
        setDiscription(res.data);
      })
      .catch((err) => {});

    // get aboput description
    axios.get(`${baseUrl}/bioInfo/api/bioInformation/get`).then((result) => {
      setAboutDetails(result.data);
    });
  }, []);

  const getTableData = (id) => {
    return description.filter((x) => x.titleID === id);
  };
console.log(title)
  return (
    <>
      <Layout>
        <div class="container">
          <div class="biography">
            <div className="row">
              <div className="col-12">
                <h2>{personalInfo?.name} </h2>
                <small className="birth-date">Born. 31 december 1970, Feni, Bangladesh</small>
              </div>

              <div class="col-sm-8 order-sm-1 order-2">
                {title.map((x) => (
                  <>
                    <h3>{x.title}</h3>
                    <table key={x.id} class="table-responsive">
                      <TableData TData={getTableData(x.id)} />
                    </table>
                  </>
                ))}
              </div>

              <div className="col-sm-4 order-sm-2 order-1">
                <div class="biography-thumb">
                  <img
                    className="img-thumbnail"
                    src={
                      personalInfo.profileImg
                        ? personalInfo.profileImg.split(":").join("s:")
                        : profileImage
                    }
                    alt="photos"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="aboutDetails  mt-2 ">
            <div className="row">
              <div className="col-12 aboutDetailsCard card p-3">
                {aboutDetails.map((x) => (
                  <div key={x.id} className=" p-1">
                    {x?.heading ? <h3>{x.heading}</h3> : null}

                    <p className="bioInformationDetails">{x.bioInformation}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default About;
