import React from "react";
import { NavDropdown } from "react-bootstrap";
import { Container, Nav, Navbar } from "react-bootstrap";
import styles from "./Navigation.module.css";
import { BiDotsHorizontal } from "react-icons/bi";
import { BiAlignLeft } from "react-icons/bi";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { useContext } from "react";
import { userContext } from "../../App";
const Navigation = ({fixed}) => {

  const { personalInfo } = useContext(userContext);
  const [toggleButton, settoggleButton] = useState(true);
  const isActive = {
 

    // backgroundColor: "#08cce6",
    borderBottom:"5px solid #08cce6"

    
  };

   
console.log(personalInfo)
  return (
    <Navbar expand="lg" bg="light"
    
    fixed={fixed && "top"}

    
   >
      <Container>
        <Navbar.Brand href="/home">
       
          <h3 className={styles.logoHeading}> {personalInfo?.name}  <span className={styles.title}>Artist </span></h3>
          {/* <h6 >   </h6> */}
        </Navbar.Brand>
        <Navbar.Toggle onClick={() => settoggleButton(!toggleButton)}>
          {toggleButton ? (
            <span className={styles.show_icon}>
              <BiAlignLeft />
            </span>
          ) : (
            <span className={styles.show_icon}>
              
              <BiDotsHorizontal />
            </span>
          )}
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto">
            <Nav.Link exact activeStyle={isActive} as={NavLink}   to="/">
              Home
            </Nav.Link>
            <NavDropdown title="Art-Work" id="basic-nav-dropdown">
              <NavDropdown.Item activeStyle={isActive} as={NavLink}  to="/painting">
              Painting 
              </NavDropdown.Item>
              <NavDropdown.Item activeStyle={isActive} as={NavLink}  to="/print">
              Print
              </NavDropdown.Item>
              <NavDropdown.Item activeStyle={isActive} as={NavLink}  to="/drawing">
              Drawing
              </NavDropdown.Item>
              <NavDropdown.Item activeStyle={isActive} as={NavLink}  to="/sculpture">
              Sculpture
              </NavDropdown.Item>
              <NavDropdown.Item activeStyle={isActive} as={NavLink}  to="/installation">
              Installation
              </NavDropdown.Item>
              <NavDropdown.Item activeStyle={isActive} as={NavLink}  to="/videoArt">
              Video Art
              </NavDropdown.Item>
             
            </NavDropdown>
            <Nav.Link activeStyle={isActive} as={NavLink}  to="/series">
            Series
            </Nav.Link>
            <Nav.Link activeStyle={isActive} as={NavLink}  to="/ebook">
              E-Book
            </Nav.Link>
            <Nav.Link activeStyle={isActive} as={NavLink}  to="/photoGraphy">
            Photo Graphy

            </Nav.Link>
            <Nav.Link activeStyle={isActive} as={NavLink}  to="/profile">
              Profile
            </Nav.Link>
            <Nav.Link activeStyle={isActive} as={NavLink}  to="/contract">
              Contact
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation;
