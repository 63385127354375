import React from 'react';
import { useState } from 'react';
import { ScaleLoader } from 'react-spinners';
import "./LoadingSeaner.css"

const LoadingSeaner = () => {

    let [color, setColor] = useState("green");
    return (
        <div style={{height:"80vh"}} className="container d-flex justify-content-center  align-items-center"> 
         <ScaleLoader color={color}  size={250} />

             </div>  
    );
};

export default LoadingSeaner;