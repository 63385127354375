import axios from "axios";
import React from "react";
import { useRef } from "react";
import { useState } from "react";
import Swal from "sweetalert2";
import { Toast } from "../Deshboard/Notification";

const CreateAchivementTitle = ({setReload}) => {

    const baseUrl=process.env.REACT_APP_API_BASEURL
  const FolderRef = useRef("");

  const heldelAchivement = (e) => {
    e.preventDefault();
    const title = FolderRef.current.value;

    axios
      .post(
        `${baseUrl}/profile/title/post`,
        { title },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setReload(data=>!data)
        FolderRef.current.value = "";
        Toast.fire({
          icon: "success",
          title: "Title  created  successfully",
        });
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `<p  href="">Please try again!!!!</p>`,
        });
      });
  };

  return (
    <div className="card shadow p-4 ">
      <h5 class="card-title"> Create Achievement Title</h5>

      <form onSubmit={heldelAchivement}>
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            placeholder="Title goes here.."
            aria-label="Recipient's username"
            aria-describedby="button-addon2"
            ref={FolderRef}
            required
          />
          <button class="btn btn-success" type="submit" id="button-addon2">
            Add
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateAchivementTitle;
