import React from "react";
import { GiCrossedSwords } from "react-icons/gi";

import styles from "./E_Book.module.css";
import { ebookContext } from "./E_Book";
import { useContext, useState } from "react";

const SideBar = ({ setSeriseData,openBook, bookList, AllBook, openFolder ,activeAllButton}) => {
  const { series, toggle, setToggle } = useContext(ebookContext);
 

  const activeToggle = (index) => {
    if (bookList.data[index] === bookList.activeStatus) {
      return styles.ebookSidebarButton + " " + styles.activeButton;
    } else {
      return styles.ebookSidebarButton;
    }
  };



  const activeAllButtonToggle = () => {
   
       
    if (activeAllButton) {
      return styles.ebookSidebarButton + " " + styles.activeButton;
    } else {
      return styles.ebookSidebarButton;
    }
  };

  return (
    <>
      <span className={styles.closeIcon}>
        <GiCrossedSwords onClick={() => setToggle(!toggle)} />
      </span>
      <li onClick={()=>{
        setSeriseData([])
        AllBook()}} className={activeAllButtonToggle()}>
          {!series ? "All Book" : "All Series"}{" "}
        </li>
      <ol className={`${styles.sideNavigationContainer}`}>
       
        {bookList?.data.map((x, index) => (
          <li
            onClick={
              !series
                ? () => openBook(x.id, index)
                : () => openFolder(x.id, index)
            }
            key={x.id}
            className={activeToggle(index)}
          >
            {!series ? x.topic.substr(0, 18) : x.name.substr(0, 30)}
          </li>
        ))}
      </ol>
    </>
  );
};

export default SideBar;
