import React from "react";
import SideBar from "./SideBar";
import styles from "./E_Book.module.css";
import Details from "./Details";
import Navigation from "../Navigation/Navigation";
import { createContext } from "react";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import useSWR from "swr"

export const ebookContext = createContext({});

const E_Book = ({series}) => {
  const fetcher = url => axios.get(url).then(res => res.data)
 
  const [toggle, setToggle] = useState(false);
  const [bookList, setbookList] = useState({
        data:[],
         activeStatus:null

  });
  const [book, setBook] = useState([]);
  const [allBook, setAllBooks] = useState(false);

   const [imageFolder, setImageFolder]=useState({
    data:[],
     activeStatus:null

})
   const [seriseImageData, setSeriseData]=useState([])

   const [activeAllButton, setActiveAllButton] = useState(true);
  // getAll Book
  
  // const serisesImage = useSWR(`https://nodes.arahman-art.com/series/getFolderData`,fetcher)

      //  if(serisesImage.data){
      //   setSeriseData(serisesImage.data)

      //  }
  useEffect(() => {
   

      axios
      .get("https://nodes.arahman-art.com/eBook/post/data/get")
      .then((res) => {
        setBook(res.data)
        setbookList({...bookList, data:res.data});
        
      })
      .then((err) => console.log(err));

      
 

      axios
      .get("https://nodes.arahman-art.com/series/getFolderData")
      .then((res) => {
       
        setSeriseData(res.data);
      })
      .then((err) => console.log(err));


      axios
      .get("https://nodes.arahman-art.com/series/getFolder")
      .then((res) => {
       
        setImageFolder({...imageFolder, data:res.data});
      })
      .then((err) => console.log(err));


    

  }, [allBook]);

  //  get Data by ID

  const openBook = (id,index) => {
   
    setbookList({...bookList, activeStatus:bookList.data[index]})
    setActiveAllButton(false)
    axios
      .get(`https://nodes.arahman-art.com/eBook/post/data/get/${id}`)
      .then((res) => {
        setBook(res.data);
      })
      .then((err) => console.log(err));

    setToggle(!toggle);
  };

  const AllBook = () => {
    setAllBooks(!allBook);
    setToggle(!toggle);

    setActiveAllButton(true)
  };


  const openFolder=(id,index)=>{

    setImageFolder({...imageFolder, activeStatus:imageFolder.data[index]});
     
    setActiveAllButton(false)

   
   
    axios
    .get(`https://nodes.arahman-art.com/series/getFolderData/${id}`)
    .then((res) => {
      setSeriseData(res.data);
    })
    .then((err) => console.log(err));
    setToggle(!toggle);
  }

  return (
    <>
      <Navigation fixed={true} />
      <ebookContext.Provider value={{series, toggle, setToggle, book, setBook,imageFolder,setImageFolder,seriseImageData }}>
        <div className={`${styles.grid}`}>
          <div className={`${styles.sidebar} ${toggle && styles.toggle}`}>
            <SideBar
              AllBook={AllBook}
              bookList={!series? bookList:imageFolder}
              openFolder={openFolder}
              openBook={openBook}
              toggle={toggle}
              setSeriseData={setSeriseData}
              activeAllButton={activeAllButton}
            />
          </div>
          <div className={`${styles.mainBody}`}>

             
            <Details seriseImageData={seriseImageData} books={book} />
          </div>
        </div>
      </ebookContext.Provider>
    </>
  );
};
       
export default E_Book;
