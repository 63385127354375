import React, { useContext } from "react";
import { userContext } from "../../App";
import Layout from "../Layout/Layout";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import styles from "./Home.module.css";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import LoadingSeaner from "../LoadingSeaner/LoadingSeaner";
import useSWR from "swr"
const Home = () => {

  const fetcher = url => axios.get(url).then(res => res.data)
  const { personalInfo } = useContext(userContext);

  const [ImageData,setImageData]=useState([])

  const sliderImageData = useSWR(`https://nodes.arahman-art.com/slider/image/get`,fetcher)

  // useEffect(() => {
  //   axios
  //     .get("https://nodes.arahman-art.com/slider/image/get")
  //     .then((data) => {
  //       setImageData(data.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });

  
  // }, []);

    return (
      <>
        <Layout>
          <div className={styles.home}>
            <Carousel autoPlay={true} infiniteLoop={true} showArrows={true}>
              {!sliderImageData ||sliderImageData?.error || sliderImageData?.data===0?  <LoadingSeaner/>:  sliderImageData?.data?.map((x) => (
                <div>
                  <img
                    style={{ maxHeight: "100vh" }}
                    src={x.image.split(":").join("s:")}
                    alt="slider images"
                  />
                </div>
              ))}
            </Carousel>
          </div>
        </Layout>
  
      
      </>
    );

  

};

export default Home;
