import React from "react";
import "./About.css";
import PanelHeader from "../../components/PanelHeader/PanelHeader.js";

import AboutForm from "./AboutForm";
import AboutPersonalDataForm from "./AboutPersonalDataForm";
import CreateAchivementTitle from "./CreateAchivementTitle";
import { useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../../../App";
import { useState } from "react";
import AchivementSetting from "./AchivementSetting";
import AddAchivementDec from "./AddAchivementDec";
import DescriptionSetting from "./DescriptionSetting";
function About() {
  const [title, setTitle] = useState([]);
  const [reload, setReload] = useState(false);
  const [details, setDetails] = useState([]);

  useEffect(() => {
    axios
      .get(`${baseUrl}/profile/title/get`)
      .then((res) => setTitle(res.data))
      .catch((err) => console.log(err));
  }, [reload]);


  const hendelView = (id) => {
    axios
      .get(`${baseUrl}/profile/details/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => setDetails(res.data))
      .catch((err) => console.log(err));
  };

   
  return (
    <>
      <PanelHeader
        size="sm"
        content={
          <div className="header text-center">
            <h2 className="title">About</h2>
          </div>
        }
      />
      <div className="content">
        <div className="row">
          <div className="col-md-8 offset-md-2">
            <AboutForm />
          </div>
          <div className="col-md-5 mt-3 ">
            <CreateAchivementTitle setReload={setReload} />
            <AchivementSetting
              title={title}
              setReload={setReload}
              hendelView={hendelView}
            />
          </div>
          <div className="col-md-7 mt-3 ">
            <AddAchivementDec titleData={title} />
           <DescriptionSetting TData={details} hendelView={hendelView} />
        
            
          </div>

          <div className="col-md-8 offset-md-2">
            <AboutPersonalDataForm />
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
