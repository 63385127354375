import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";

import { useForm } from "react-hook-form";
import { BsCloudUpload } from "react-icons/bs";
import Swal from "sweetalert2";
const UploadImages = ({folder}) => {

  const [images, setImages] = useState([]);
  const [Select, setSelect] = useState(false);

  const [imgFolder, setImgFolder] = useState(true);


  const {
    register,
    handleSubmit,
    
  } = useForm();

  const hendelImageUploaded = (e) => {
    if (e.target.files) {
      setSelect(false);
      if (e.target.files[0].size > 1000000) {
        setImages([])

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "File Size too large ",
          footer:
            "<p> Maximum File Size Allow 1MB</p>",
        });

      }else{

        setImages(e.target.files);
      }
    }
  };

  const hendelFolder = (e) => {
    if (images.length > 0) {
      if (e.target.value !== "false") {
        setImgFolder(true);
      } else {
        setImgFolder(false);
      }
    }
  };

  const onSubmit = (data) => {
    let formData = new FormData();

    if (images.length > 0) {
      setSelect(false);
      if (data.imgFolder === "false") {
        setImgFolder(false);
      } else {
        setImgFolder(true);
        formData.append("image", images[0]);
        formData.append("FolderID", data.imgFolderID);
        formData.append("vertical", data.vertical);
        formData.append("squire", data.square);
        axios
          .post("https://nodes.arahman-art.com/series/folder/data/post", formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((response) => {
            if (response.data) {
              setImages([]);
              Swal.fire("Good job!", "Added Successfully", "success");
              
            }
          })
          .catch((err) => {
            setImages([]);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer:
                "<p> Please check your Image size and Image type and try again</p>",
            });
          });
      }
    } else {
      setSelect(true);
    }
  };

// Get folder name 

  return <>

<div className="card p-4">
      <form onSubmit={handleSubmit(onSubmit)} enctype="multipart/form-data">
        <div className="mb-3">
          {!imgFolder && (
            <p className="text-danger">Please selected a folder </p>
          )}
          <select
            class="form-select"
            {...register("imgFolderID", { required: true })}
            aria-label="Default select example"
            onChange={hendelFolder}
          >
            <option value="false" selected>
              Select Images Folder
            </option>
          
            {
                folder.map(x=>   <option value={x.id}>{x.name}</option> )
            }
          </select>
        </div>
        <div className="mb-3">
          <p>Images Size <small className="text-primary">(optional)</small></p>
          <small className="text-secondary pb-1">[Default Size Horizontal]</small>
          <div className="pb-3">
          <input
            class="form-check-input"
            type="checkbox"
            value=""
            id="flexCheckDefault3"
            {...register("vertical", { required: false })}
          />
          {"  "}
          <label class="form-check-label" for="flexCheckDefault3">
            Vertical
          </label>

          </div>
          <div>
          <input
            class="form-check-input"
            type="checkbox"
            value=""
            id="flexCheckDefault4"
            {...register("square", { required: false })}
          />
          {"  "}
          <label class="form-check-label" for="flexCheckDefault4">
          Square
          </label>

          </div>
         
        </div>
        <div className="mb-3 weight-UPimg">
          <h6>Select photo/photos</h6>

          <input
            style={{ display: "none" }}
            onChange={hendelImageUploaded}
            name="image"
            id="seriesFile"
            type="file"
            accept="image/*"
            multiple={false}
          />

          <label htmlFor="seriesFile">
            <BsCloudUpload /> Upload Photo
          </label>
        </div>
        {Select && (
          <p className="text-danger"> Please Select minimum One image </p>
        )}
        <p style={{ color: "gray" }}>
          {images.length > 0 && images.length + "  " + "Image Selected "}
        </p>
        <button className="btn btn-outline-primary" type="submit">
          Save
        </button>
      </form>
    </div>
    

    
  

  </>;
};

export default UploadImages;
