import axios from "axios";
import React from "react";
import { Table } from "react-bootstrap";
import { FiEdit } from "react-icons/fi";
import { GrFormView } from "react-icons/gr";
import { MdDeleteForever } from "react-icons/md";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { baseUrl } from "../../../App";
import { Toast } from "../Deshboard/Notification";



const AchivementSetting = ({title,setReload,hendelView}) => {

   let history=useHistory()

  const handelEdit = async (id) => {
    const { value: folderName } = await Swal.fire({
      title: "Enter Edited Folder Name",
      input: "text",
      inputLabel: "Your Folder Name",
      inputPlaceholder: "Enter your folder name",
    });

    if (folderName) {
      const data = { id, folderName };

      axios
        .patch(`${baseUrl}/profile/title/edit`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {

            setReload(data=>!data)
          Toast.fire({
            icon: "success",
            title: "Update Achievement Title successfully",
          });
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `<p  href="">Please try again!!!!</p>`,
          });
        });
    }
  };

  // hendle delete title 

  const hendelDelete=(id)=>{
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${baseUrl}/profile/title/delete/${id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((result) => {
            Swal.fire("Deleted!", "Title  has been deleted.", "success");
            setReload(reload=>!reload)
          })
          .catch((err) => {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Something went wrong!',
              footer: `<p  href="">Please try again!!!!</p>`
            })
          });
      }
    });

     
  }

  
  return (
    <>
      <div className="card p-4 shadow my-3">
        <h3 className="text-center text-success"> Achievement  List </h3>

        <Table striped bordered hover size="sm">
          <thead>
            <tr className="table-heading-data">
              <th className="text-center">Serial</th>
              <th className="text-center">Title</th>
              <th className="text-center">Action</th>
            </tr>
          </thead>
          <tbody className="folder-list-body">
            {title.map((x, index) => {
              return (
                <tr key={x.id}>
                  <td className="text-center">{index + 1}</td>
                  <td className="folderName text-center" >{x.title}</td>
                  <td className="text-center">
                    <button onClick={() => handelEdit(x.id)} className="btn">
                      <FiEdit className="seriesFolderEditeIcon" />
                    </button>
                    <button onClick={() => hendelView(x.id)} className="btn">
                      <GrFormView className="viewIcon" />
                    </button>
                    <button onClick={() => hendelDelete(x.id)} className="btn text-danger">
                      <MdDeleteForever className="deleteIcon" />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default AchivementSetting;
