import React from "react";

const TableData = ({ TData }) => {

  return (
    <>
      {TData.map((x) => (
        <tr key={x.id}>
          <td>{x.fromTo}</td>
          <td>:</td>
          <td>{x.description}</td>
        </tr>
      ))}
    </>
  );
};

export default TableData;
