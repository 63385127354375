import React from "react";
import Layout from "../Layout/Layout";

import "./Contract.css";
import ContractForm from "./ContractForm";
import ContractInfo from "./ContractInfo";

const Contract = () => {
  return (

    <Layout>  
    
    <div id="contract">
     <div className="container">
     <div className="row">
          <div className="col-lg-10 offset-lg-1">
          <ContractInfo/>
          </div>
          <div className="col-lg-10 offset-lg-1">
            <ContractForm />
          </div>
         
        </div>

     </div>
 
     
      </div>
  
    </Layout>
  );
};

export default Contract;
