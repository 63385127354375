import "bootstrap/dist/css/bootstrap.css";
import "./adminDeshbord/assets/css/demo.css";
import "./adminDeshbord/assets/scss/now-ui-dashboard.scss";
import "./App.css";
import "./resource/GlobalStyle/globalColor.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./components/Home/Home";
import ProjectContainer from "./components/Projects/ProjectContainer/ProjectContainer";
import { useEffect, useState } from "react";
import Contract from "./components/Contract/Contract";
import About from "./components/About/About";
import AdminLayout from "./adminDeshbord/layouts/Admin";
import LogIn from "./adminDeshbord/LogIn/LogIn";
import { createContext } from "react";
import PrivateRoute from "./adminDeshbord/LogIn/PrivateRoute";
import axios from "axios";
import E_Book from "./components/E_Book/E_Book";
import Layout from "./components/Layout/Layout";
import { SWRConfig } from "swr";
import useSWR from "swr";
import LoadingSeaner from "./components/LoadingSeaner/LoadingSeaner";
export const userContext = createContext();
export const baseUrl=process.env.REACT_APP_API_BASEURL

function App() {
  const fetcher = url => axios.get(url).then(res => res.data)
  const [projectImg, setProjectImg] = useState([]);
  const [projectDetails, setProjectDetails] = useState([]);
  const [loginUser, setLoginUser] = useState({});
  const [personalInfo, setPersonalInfo] = useState({});


  // Filter function for filter project images data and details

  const FilterProject = (data, fileName) => {
    return data.filter((x) => x.fileName === fileName);
  };


  const { data, error } = useSWR(`https://nodes.arahman-art.com/projects/data/get`,fetcher)
   



  // get projectData from database
  useEffect(() => {
  //   axios.get("https://nodes.arahman-art.com/projects/data/get").then((result) => {
  //     setProjectImg(result.data);
  //   });

    axios
      .get("https://nodes.arahman-art.com/projects/details/data/getDetails")
      .then((result) => {
        setProjectDetails(result.data);
      });
    axios
      .get("https://nodes.arahman-art.com/adminInformation/api/contractInformation/get")
      .then((result) => {
        setPersonalInfo(result.data[0]);
      });
  }, []);

  if(!data || error){

     return <> <LoadingSeaner/> </>
  }
console.log(data,error)
  return (
    <userContext.Provider value={{ loginUser, setLoginUser, personalInfo }}>
       <SWRConfig
        value={{
          refreshInterval: 3000,
          fetcher: (url) => axios.get(url).then((res) => res.data),
        }}
      >
      <Router>
        <Switch>
          <Route path="/" exact component={Home} />
      
          <Route exact path="/print">
          <Layout>
          <ProjectContainer
              title="Print"
              projectImg={FilterProject(data, "print")}
              projectDetails={FilterProject(projectDetails, "print")}
            />
            
            </Layout>
         
          </Route>
          <Route exact path="/painting">
        <Layout>
        <ProjectContainer
              title="Painting"
              projectImg={FilterProject(data, "Painting")}
              projectDetails={FilterProject(projectDetails, "painting")}
            />
        </Layout>
         
          </Route>
          <Route exact path="/drawing">
          <Layout>   <ProjectContainer
              title="Drawing"
              projectImg={FilterProject(data, "drawing")}
              projectDetails={FilterProject(projectDetails, "drawing")}
            /></Layout>
         
          </Route>
          <Route exact path="/sculpture">

            <Layout>  <ProjectContainer
              title="Sculpture"
              projectImg={FilterProject(data, "sculpture")}
              projectDetails={FilterProject(projectDetails, "sculpture")}
            /> </Layout>
           
          </Route>
         
          <Route exact path="/installation">
          <Layout> 
          <ProjectContainer
              title="Installation"
              projectImg={FilterProject(data, "installation")}
              projectDetails={FilterProject(projectDetails, "installation")}
            />
            
            </Layout>
         
          </Route>
          <Route exact path="/videoArt">
            <Layout>   <ProjectContainer
              title="Video Art"
              projectImg={FilterProject(data, "videoArt")}
              projectDetails={FilterProject(projectDetails, "videoArt")}
            /> </Layout>
         
          </Route>
          <Route exact path="/photoGraphy">
            <Layout>   <ProjectContainer
              title="Photo Graphy"
              projectImg={FilterProject(data, "photoGraphy")}
              projectDetails={FilterProject(projectDetails, "photoGraphy")}
            /> </Layout>
         
          </Route>

          <Route exact path="/contract">
            <Contract />
          </Route>

          <Route exact path="/profile">
            <About />
          </Route>
          <Route exact path="/ebook">
            <E_Book series={false} />
          </Route>
          <Route exact path="/series">
            <E_Book series={true} />
          </Route>
          <Route exact path="/login">
            <LogIn />
          </Route>

          <PrivateRoute path="/admin">
            <Route render={(props) => <AdminLayout {...props} />} />
          </PrivateRoute>
        </Switch>
      </Router>
      </SWRConfig>
    </userContext.Provider>
  );
}

export default App;
