import axios from "axios";
import React from "react";
import { Table } from "react-bootstrap";
import { FiEdit } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { Toast } from "../../Deshboard/Notification";

const FolderSetting = ({ folder,setReload }) => {

   let history=useHistory()

  const handelEdit = async (id) => {
    const { value: folderName } = await Swal.fire({
      title: "Enter Edited Folder Name",
      input: "text",
      inputLabel: "Your Folder Name",
      inputPlaceholder: "Enter your folder name",
    });

    if (folderName) {
      const data = { id, folderName };

      axios
        .patch("https://nodes.arahman-art.com/series/edite", data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {

            setReload(reload=>!reload)
          Toast.fire({
            icon: "success",
            title: "Update Folder Name successfully",
          });
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `<p  href="">Please try again!!!!</p>`,
          });
        });
    }
  };

  const folderImageSetting=(id)=>{

    history.push(`/admin/SeriesProjects/${id}`)

  }
  return (
    <>
      <div className="card p-4 shadow my-3">
        <h3 className="text-center text-success"> Folder List </h3>

        <Table striped bordered hover size="sm">
          <thead>
            <tr className="table-heading-data">
              <th>Serial</th>
              <th>Folder Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody className="folder-list-body">
            {folder.map((x, index) => {
              return (
                <tr key={x.id}>
                  <td>{index + 1}</td>
                  <td className="folderName" onClick={()=>folderImageSetting(x.id)}>{x.name}</td>
                  <td>
                    {" "}
                    <button onClick={() => handelEdit(x.id)} className="btn">
                      <FiEdit className="seriesFolderEditeIcon" />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default FolderSetting;
