import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { baseUrl } from "../../../App";

const AddAchivementDec = ({ titleData }) => {
  const [title, setTitle] = useState(true);
  const [description, setDescription] = useState("");
  const [fromTo, setFromTo] = useState("");

  const { register, handleSubmit } = useForm();

  //   hendel file selected or not
  const hendelFolder = (e) => {
    if (e.target.value !== "false") {
        setTitle(true);
    }
  };

  // hendel form data
  const onSubmit = (data) => {



   
    if (data.title !== "false") {
    
      setTitle(true);

      data.id = Date.now();
      axios
        .post(`${baseUrl}/profile/description/post`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          setDescription("");
          setFromTo("");
          Swal.fire("Good job!", "Added Successfully", "success");
        })
        .catch((err) => {
            setDescription("");
            setFromTo("");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `<p  href="">Please try again!!!!</p>`,
          });
        });
    } else {
        setTitle(false);
    }
  };

  return (
    <div className="card p-4">
    
      <h3 className="text-center text-success">  Add Description</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-3">
          {!title && (
            <p className="text-danger">Please selected a Title </p>
          )}
          <select
            class="form-select"
            {...register("title", { required: true })}
            aria-label="Default select example"
            onChange={hendelFolder}
            required
          >
            <option value="false" selected>
              Select Title
            </option>
            {titleData.map((x) => (
              <option value={x.id}>{x.title}</option>
            ))}
          </select>
        </div>
        <div className="mb-3">
          <label className="form-label" for="exampleFormControlInput1">
            From - To <small className="text-primary">(1999-2000)</small>
          </label>

          <input
            type="text"
            className="form-control"
            id="exampleFormControlInput1"
            placeholder="1999-2000"
            {...register("fromTo", { required: true })}
            onChange={(e) => setFromTo(e.target.value)}
            value={fromTo}
            required
          />
        </div>
        <div className="mb-3">
          <label for="exampleFormControlTextarea1" className="form-label">
          Description
          </label>
          <input
            class="form-control"
            id="exampleFormControlTextarea1"
            rows="3"
            {...register("description", { required: true })}
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            required
          ></input>
        </div>
        <button className="btn btn-outline-primary" type="submit">
          Save
        </button>
      </form>
    </div>
  );
};

export default AddAchivementDec;
