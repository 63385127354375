import React from "react";
import { useState } from "react";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { Button, Form } from "react-bootstrap";
import PanelHeader from "../../components/PanelHeader/PanelHeader";
import "./E_Book.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import axios from "axios";
import { Toast } from "../Deshboard/Notification";
import UnUsedImage from "./UnUsedImage";
import Swal from "sweetalert2";
import BookList from "./BookList";
const E_Book = (props) => {
  const [reloadBookList, setReloadBookList]=useState(false)
  const post = props.location.state ? props.location.state.post : "";
  //if post passed, than get id and description
  const description = post ? post.description : "";
  const id = post ? post._id : "";

  //if post passed, than convert editor content, if not - create empty
  const editorContent = post
    ? EditorState.createWithContent(convertFromRaw(JSON.parse(description)))
    : EditorState.createEmpty();
  //local state of editor
  const [editorState, setEditorState] = useState({
    editorState: editorContent,
  });
  //local state of topic
  const [topic, setTopic] = useState(post.topic);

  //   hendel book name
  const hendelBookName = (e) => {
    setTopic(e.target.value);
  };
  //change local state of editor
  const handleEditorChange = (editorState) => {
    setEditorState({ editorState });
  };

  // api : https://nodes.arahman-art.com/eBook/post/img
  //   hendle image
  function uploadImageCallBack(file) {
    return new Promise((resolve, reject) => {
      const data = new FormData();
      data.append("image", file);

      axios
        .post("https://nodes.arahman-art.com/eBook/post/img", data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => resolve({ data: { link: res.data } }))
        .catch((err) => reject(err));
    });
  }

  const hendelSubmit = (e) => {
    e.preventDefault();
    const newPost = {
      id,
      topic,
      description: JSON.stringify(
        convertToRaw(editorState.editorState.getCurrentContent())
      ),
    };

    axios
      .post("https://nodes.arahman-art.com/eBook/post/data/post", newPost, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setReloadBookList(!reloadBookList)
        setTopic("");
        setEditorState("");
        Toast.fire({
          icon: "success",
          title: " successfully added",
        });
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `<p  href="">Please try again!!!!</p>`,
        });
      });
  };

  return (
    <>
      <PanelHeader
        size="sm"
        content={
          <div className="header text-center">
            <h2 className="title"> E-Book Setting</h2>
          </div>
        }
      />
      <div className="content">
        <div className="row">
          <div className="col-md-8 offset-md-2 card p-4">
            <Form onSubmit={hendelSubmit}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label> Enter Your Book Name</Form.Label>
                <Form.Control
                  value={topic}
                  onChange={hendelBookName}
                  type="text"
                  placeholder="Enter your book Name"
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Description </Form.Label>

                <Editor
                  editorState={editorState.editorState}
                  onEditorStateChange={handleEditorChange}
                  wrapperClassName="demo-wrapper"
                  editorClassName="demo-editor"
                  toolbar={{
                    inline: { inDropdown: true },
                    list: { inDropdown: true },
                    textAlign: { inDropdown: true },
                    link: { inDropdown: true },
                    history: { inDropdown: true },
                    image: {
                      uploadCallback: uploadImageCallBack,
                      alt: { present: true, mandatory: false },
                    },
                  }}
                />
              </Form.Group>

              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </div>
        </div>
        <div className="row my-4">
          <div className="col-md-6  card p-4">
          <BookList reloadBookList={reloadBookList} setReloadBookList={setReloadBookList} />
           
          </div>

          <div className="col-md-6  card p-4">
          <UnUsedImage />
          </div>
        </div>
      </div>
    </>
  );
};

export default E_Book;
